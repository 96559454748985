export default {
  features: {
    addressFromLocalStorageEnabled: true,
    addressValidationInRegistrationEnabled: true,

    agreementsEblokEnabled: false,
    agreementsEmailSmsEnabled: true,
    agreementsGeneralProgramTermsTextEnabled: false,
    agreementsPersonalDataEnabled: false,
    agreementsSmsChannel: ['EMAIL'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: false,

    babyClubEnabled: false,
    birthDateEnabled: true,
    birthDateOverEnabled: 18,
    birthDateRequired: true,
    birthDateValidateOnSubmit: false,

    companyDataPrefillEnabled: false,
    companyItInvoicingEnabled: true,

    competitionService: {
      competitionImagePath: '',
    },

    correspondenceEmailEnabled: false,
    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: false,
    discountEnabled: true,
    downloadInvoiceEnabled: false,

    /*eslint-disable */
    favoriteFilterCategoriesIds: [6027, 6053, 6099, 6174, 6229, 6272, 6301, 6313],

    generalTermsTextEnabled: false,

    loyaltyCardBackImagePath: '',
    loyaltyCardCodeLengthCheckEnabled: false,
    loyaltyCardImagePath: '/loyalty-card/general-loyalty-card.jpg',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-green.svg',
    loyaltyCardV2: false,

    phoneNumberRequired: true,

    priceEnabled: true,
    priceSummaryEnabled: false,
    registrationAddressEnabled: false,
    removeUserEnabled: false,
    sexEnabled: false,
    sexThirdOptionEnabled: true,
    sexRequiredEnabled: true,
    typingInLoginEmailEnabled: true,
    unitPriceEnabled: true,
    ordersFilterFromEshopEnabled: false,

    emailRestrictedDomainEnabled: false,

    surveyEnabled: false,
    userFillableDetails: {
      pregnant: false,
      breastFeeding: false,
      allergies: false,
      treatments: false,
      height: false,
      weight: false,
    },
  },
}
