import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import DEFAULT from './DEFAULT.mjs'
import INPOST_ITA_BOX from './INPOST_ITA_BOX.mjs'
import INPOST_ITA_POK from './INPOST_ITA_POK.mjs'
import PHARMACY from './PHARMACY.mjs'
import config from '../index.mjs'
import map from './map.mjs'

export default defineAppConfig({
  mapConfig: {
    ...config,
    ...map,
  },
  placePrototypes: {
    DEFAULT,
    INPOST_ITA_BOX,
    INPOST_ITA_POK,
    PHARMACY,
  },
})
