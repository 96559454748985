import { envBool } from '@nsf/utils/EnvUtils.js'

export default {
  footer: {
    emailSubscription: true,
    feedatyMerchantId: process.env.FEEDATY_MERCHANT_ID,
    feedatyWidget: envBool(process.env.FEEDATY_ENABLED, false),
    flags: true,
    footerCertificateImages: [],
    footerCertificateText: 'Parafarmacia Autorizzata dal Ministero della Salute codice univoco 016138 | PARAFARMACIA DRMAX TELGATE - via Lombardia, 25/27 - 24060 - Telgate (BG). Farmacista direttore iscritto all’Ordine dei Farmacisti di Milano, Lodi, Monza e Brianza con n. 15562. Puoi verificare l\'autorizzazione ministeriale cliccando sul logo sotto.',
    footerCertificateTextClass: '',
    footerTopAbsoluteImages: [],
    footerTopAbsoluteImagesHeight: 0,
    footerTopLogos: [],
    mobileApplications: false,
    paymentsImages: [
      {
        src: '/images/footer/stripe.svg',
        alt: 'Stripe',
        width: 128,
        height: 29,
      },
      {
        src: '/images/footer/mastercard-securecode.svg',
        alt: 'MasterCard SecureCode',
        width: 82,
        height: 29,
      },
      {
        src: '/images/footer/verified-by-visa.svg',
        alt: 'Verified by Visa',
        link: 'https://www.visaitalia.com/paga-con-visa/pagamenti-sicuri/visa-secure.html',
        width: 67,
        height: 29,
      },
      {
        src: '/images/footer/paypal.svg',
        alt: 'Paypal',
        width: 32,
        height: 29,
      },
      {
        src: '/images/footer/american-express.png',
        alt: 'American Express',
        width: 107,
        height: 29,
      },
      {
        src: '/images/footer/satispay.png',
        alt: 'Satispay',
        width: 136,
        height: 33,
      },
    ],
    suklImages: true,
    useFooterCertificateBlock: true,
  },
}
