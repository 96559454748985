import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  allowedSources: [
    '*.algolia.net',
    '*.algolianet.com',
    '*.clarity.ms',
    '*.drmax-gl.dev',
    '*.drmax-gl.space',
    '*.drmax-it.space',
    '*.drmax.it',
    '*.drmax.net',
    '*.drmax.zone',
    '*.drmax-gl.live',
    '*.facebook.com',
    '*.facebook.net',
    '*.fbcdn.net',
    '*.google-analytics.com',
    '*.google.com',
    '*.googleapis.com',
    '*.instagram.com',
    '*.sentry.io',
    '*.twitter.com',
    'analytics.prezzifarmaco.it',
    'analytics.prezzifarmaco.it/api/v1/event/trackTransaction',
    'api.luigisbox.com',
    'bam.eu01.nr-data.net',
    'bat.bing.com',
    'cdn.evergage.com',
    'cdn.evgnet.com',
    'cdn.jsdelivr.net',
    'cdn.luigisbox.com',
    'cdn.speedcurve.com',
    'cdn-4.convertexperiments.com',
    'cdnjs.cloudflare.com',
    'cdp.drmax.meiro.io',
    'cdp.drmaxit.meiro.io',
    'connect.facebook.net',
    'consent.cookiebot.com',
    'consentcdn.cookiebot.com',
    'data1.lacedefe.com',
    'drmaxbdcsro.germany-2.evergage.com',
    'drmaxitaepmhub.azurewebsites.net',
    'drmaxitaepmhubtest.azurewebsites.net',
    'dynamic.criteo.com',
    'events.drmax.it',
    'fledge.eu.criteo.com',
    'fonts.gstatic.com',
    'googleads.g.doubleclick.net',
    'gum.criteo.com',
    'hal9000.redintelligence.net',
    'image-resizer-svc.drmax-gl.live',
    'image-resizer-svc.drmax-gl.space',
    'insights.algolia.io',
    'js-agent.newrelic.com',
    'lux.speedcurve.com',
    'measurement-api.criteo.com',
    'openfpcdn.io',
    'pagead2.googlesyndication.com',
    'placement-service.drmax-gl.live',
    'placement-service.drmax-gl.space',
    'rtp.persoo.ai',
    's.kelkoogroup.net',
    's.kk-resources.com',
    'scripts.persoo.cz',
    'search-service.drmax-gl.space',
    'secureaud.solocpm.com',
    'sslwidget.criteo.com',
    'static.cloudflareinsights.com',
    'static.criteo.net',
    'stats.g.doubleclick.net',
    'td.doubleclick.net',
    'tpc.googlesyndication.com',
    'tracking.trovaprezzi.it',
    'trk.beintoo.net',
    'unpkg.com',
    'vwvwvwvw.b-cdn.net', // what is this?
    'widget.feedaty.com',
    'www.drmax.it',
    'www.facebook.com',
    'www.googleadservices.com',
    'www.googleoptimize.com',
    'www.googletagmanager.com',
    'www.gstatic.com',
    'www.mainadv.com',
    'www.paypal.com',
    'www.pharmaidea.com',
    'www.recaptcha.net',
    'www.sandbox.paypal.com',
    'www.trovaprezzi.it',
    'www.youtube-nocookie.com',
    'www.youtube.com',
  ],
})
